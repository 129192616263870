@font-face {
font-family: '__AkkuratLL_27b47d';
src: url(/b/a/ecom-website/faf00590773bdc0e986ec70d7e0b3489dcbd4495/_next/static/media/26a8fb288ae942e8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__AkkuratLL_27b47d';
src: url(/b/a/ecom-website/faf00590773bdc0e986ec70d7e0b3489dcbd4495/_next/static/media/52aded988c1a1caf-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: light;
}@font-face {font-family: '__AkkuratLL_Fallback_27b47d';src: local("Arial");ascent-override: 91.39%;descent-override: 22.87%;line-gap-override: 0.00%;size-adjust: 105.04%
}.__className_27b47d {font-family: '__AkkuratLL_27b47d', '__AkkuratLL_Fallback_27b47d'
}.__variable_27b47d {--font-sans: '__AkkuratLL_27b47d', '__AkkuratLL_Fallback_27b47d'
}

@font-face {
font-family: '__EditorialNew_c11fae';
src: url(/b/a/ecom-website/faf00590773bdc0e986ec70d7e0b3489dcbd4495/_next/static/media/2f495048b025fb3f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__EditorialNew_c11fae';
src: url(/b/a/ecom-website/faf00590773bdc0e986ec70d7e0b3489dcbd4495/_next/static/media/d6bb81b4bc659df4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: italic;
}@font-face {font-family: '__EditorialNew_Fallback_c11fae';src: local("Arial");ascent-override: 106.99%;descent-override: 36.47%;line-gap-override: 12.16%;size-adjust: 82.25%
}.__className_c11fae {font-family: '__EditorialNew_c11fae', '__EditorialNew_Fallback_c11fae'
}.__variable_c11fae {--font-serif: '__EditorialNew_c11fae', '__EditorialNew_Fallback_c11fae'
}

